.app {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appLogo {
  height: 150px;
  pointer-events: none;
}

.appHeader {
  background-color: #ffffff;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc( 10px + 2vmin );
  color: black;
  padding: 10px;
}

/*============================================================================*/

/*Authentication*/

.authenticationPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  float: none;
  margin: auto;
  height: 100vh;
}

.floatPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 500px;
  height: 300px;
  background-color: #FFFBF6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.titlePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100px;
  width: 100%;
}

.titleHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.passwordFieldPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.passwordField {
  border: 1px solid#FA80A6;
  width: 182px;
  height: 30px; 
  border-radius: 11px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 10px;
}

.submitButtonPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.submitButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/*============================================================================*/

.godUsers {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  width: 95%;
  height: 50px;
  background-color: #FFFBF6;
  justify-content: center;
  margin: 10px auto;
  border-radius: 11px;
  border: .5px solid #FA80A6;
}

/*============================================================================*/

.godPanel {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  width: 95%;
  height: 100%;
  background-color: #FFFBF6;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 11px;
  border: .5px solid #FA80A6;
}

/*============================================================================*/

.userGodmodePanel {
  display: flex;
  flex-direction: column;
  min-width: 98%;
  height: 100%;
}

/*============================================================================*/

.userGodmodeRow:first-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 30px 10px 30px;
}

.userGodmodeRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 30px 10px 30px;
}

.userGodmodeRow:last-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 30px 20px 30px;
}

.userGodmodeRow:only-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 30px 20px 30px;
}

.noGodsRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0px 20px 0px;
}

/*============================================================================*/

.noGodsColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/*============================================================================*/

.userIdColumn {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: left;
  align-items: center;
}

/*============================================================================*/

.pitchVideoUrlColumn {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: auto;
  justify-content: left;
  align-items: center;
}

/*============================================================================*/

.mainPanel {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  width: 95%;
  height: 100%;
  background-color: #FFFBF6;
  text-align: center;
  margin: auto;
  border-radius: 11px;
  border: .5px solid #FA80A6;
}

/*============================================================================*/

.assignmentPanel {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: auto;
}

/*============================================================================*/

.userSelectionPanel {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background-color: #FFFBF6;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.userPanel {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #FFFBF6;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

/*============================================================================*/

.controlPanel {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  background-color: #FFFBF6;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

/*============================================================================*/

.alreadySelectedUserPanel {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 135px;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  border: .5px solid #FA80A6;
}

.alreadySelectedVideoPanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 232px;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  border: .5px solid #FA80A6;
}

.smallVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 112px;
  overflow: hidden;
  border-radius: 11px;
  border: 1px solid #FA80A6;
}

.smallVideoPlayer {
  max-width: 100%;
  max-height: 100%;
}

/*============================================================================*/

.videoSelectionPanel {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background-color: #FFFBF6;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.videoPanel {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #FFFBF6;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 540px;
  width: 302px;
  overflow: hidden;
  border-radius: 11px;
  border: 1px solid #FA80A6;
}

.videoPlayer {
  max-width: 100%;
  max-height: 100%;
}

.buttons-container {
  display: flex;
  gap: 10px; 
  justify-content: center;
}

/*============================================================================*/

.userEmailAddresses {
  width: 302px;
  height: 495px;
  border-radius: 4px;
  border: .5px solid #FA80A6;
}

.previousVideoImage,
.previousUsersImage {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin: 0px 10px;
}

.nextVideoImage, 
.nextUsersImage {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin: 0px 10px;
}

/*============================================================================*/

.buttonPanel {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 20px;
}

.selectUserButtonPanel {
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.assignGodButtonPanel {
  display: flex;
  flex-direction: row;
  width: 20%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.selectVideoButtonPanel {
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/*============================================================================*/
.userGodmodePanel {
  position: relative;
}

.userGodmodePanel table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
}

.userGodmodePanel th, .userGodmodePanel td {
  border: 1px solid #ddd;
  padding: 8px;
}

.userGodmodePanel th {
  background-color: #f2f2f2;
  text-align: center;
}

.userGodmodePanel tr:hover {
  background-color: #f1f1f1;
}

button.toggle-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #ff8181;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
  width: 30px;
  height: 30px;
  text-align: center;
}

button.toggle-button:hover {
  background-color: #ff8181;
}

.floating-div {
  position: absolute;
  top: 0;
  left: 110%; /* Adjust to your preference */
  width: 300px; /* Adjust to your preference */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.godMode-td-cell {
  display: flex;
  justify-content: center;
}

.userGodmodePanel table {
  width: 100%;
  border-collapse: collapse;
}

.userGodmodePanel thead th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust as needed */
  z-index: 1;
  border-bottom: 2px solid #ddd;
}

.userGodmodePanel {
  position: relative;
  max-height: 400px; 
  overflow-y: auto;
}

.userGodmodePanel table {
  width: 100%;
  border-collapse: collapse;
}

.userGodmodePanel thead {
  position: sticky;
  top: 0;
  background-color: #fff; 
}

.userGodmodePanel th, .userGodmodePanel td {
  padding: 8px;
  border: 1px solid #ddd;
}

.userGodmodePanel .dropdown-content {
  background-color: #f9f9f9; 
}



/* App.css or main.css */

.timeslot-select {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 11px;
  border: .5px solid #FA80A6;
  border-radius: 4px;
  box-sizing: border-box;

}

.timeslot-select:focus {
  border-color: #ff8181;
  box-shadow: 0 0 5px #ff8181;
  outline: none;
}

.select-container {
  display: flex;
  flex-direction: column;
}

.select-container input {
  padding: 7px;
  margin-bottom: 8px;
  font-size: 16px;
  border-radius: 11px;
  border: .5px solid #FA80A6;
  border-radius: 4px;
  box-sizing: border-box;
}

.select-container input:focus {
  border-color: #ff8181;
  box-shadow: 0 0 5px #ff8181;
  outline: none;
}

#userEmailAddresses {
  appearance: none;
  -webkit-appearance: none; /* for Safari */
  -moz-appearance: none; /* for Firefox */
}

#userEmailAddresses:focus {
  outline: none;
  box-shadow: none;
}

select.userEmailAddresses option {
  border-top: 1px inset #ff8181 !important;
  border-left: none !important;
  border-right: none !important;
  padding: 5px;
}

/* Remove border-top from the first option */
select.userEmailAddresses option:first-of-type {
  border-top: none !important;
}

select.userEmailAddresses option:last-of-type {
  border-bottom: 1px inset #ff8181 !important;
}

#userEmailAddresses   option:hover {
  background-color:  #ff8181 !important;
  padding: 5px;
}

#userEmailAddresses option:checked {
  background-color:  #ff8181 !important;
  padding: 5px;
}

#userEmailAddresses option:active {
  background-color:  #ff8181 !important;
  padding: 5px;
}
