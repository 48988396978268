/* ****************************************** */
/*            Root CSS Information            */
/* ****************************************** */

:root {
  --ff-primary: "BROmny", sans-serif;
  --ff-secondary: "BayshoreBayshore", sans-serif;
  --fw-thin: 100;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;
  --fw-extra-bold: 800;
  --color-white: #ffffff;         /* White */
  --font-color-body: #333333;
  --font-color-link: #FF80A6;     /* Pink */
  --font-color-disabled: #FFB3CA; /* Light Pink */
  --accent-color-dark: #FF8181;   /* Coral */
  --accent-color-light: #FFA0A0;  /* Light Coral */
  --apricot: #FFE4CC;             /* Apricot */
  --cream: #FFFBF6;               /* Cream */
  --peach: #FEEADF;               /* Peach */
  --gradient-gold1: #FFAA5B;      /* Gold Gradient Stop 1 */
  --gradient-gold2: #FFE18B;      /* Gold Gradient Stop 2 */   
  --gold-gradient: linear-gradient( 90deg, #FFAA5B 0%, #FFE18B 100% );
}

/* Box sizing rules */
/* *,
*::before,
*::after {
  box-sizing: border-box;
} */

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

html,
body {
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: var( --color-white );
}

/* ****************************************** */
/*                  Resets                    */
/* ****************************************** */

div {
  margin: 0px;
  padding: 0px;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  margin: 0px;
  padding: 0px;
}


input,
button,
textarea,
select {
  font: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

/* ****************************************** */
/*         TYPOGRAPHY (mobile first!)         */
/* ****************************************** */

* {
  font-family: var(--ff-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ff-primary);
  font-weight: var(--fw-semi-bold, 600);
  font-style: normal;
  color: var(--accent-color-dark, #ff8181);
  line-height: 120%; /* 48px */
}

h1 {
  font-size: 2.5rem; /* 40px */
  letter-spacing: -0.8px;
}

h2 {
  font-size: 2.25rem; /* 36px */
  letter-spacing: -0.8px;
}

h3 {
  font-size: 2rem; /* 32px */
  letter-spacing: -0.8px;
}

h4 {
  font-size: 1.5rem; /* 24px */
  letter-spacing: -0.8px;
}

h5 {
  font-size: 1.25rem; /* 20px */
  letter-spacing: -0.8px;
}

h6 {
  font-size: 1.125rem; /* 18px */
  letter-spacing: -0.8px;
}

p {
  font-size: 1rem; /* 16px */
  letter-spacing: -0.16px;
  font-weight: 400;
  line-height: 150% /* 24px */
}

.large {
  font-size: 1.125rem; /* 18px, different than the H6 */
  letter-spacing: -0.18px;
}

.small {
  font-size: 0.875rem; /* 14px */
  letter-spacing: -0.16px;
}

.xsmall {
  font-size: 0.75rem; /* 12px */
  letter-spacing: -0.12px;
}

.subheading {
  color: #a4a4a4;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
}

.icon {
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 800px) {
  h1 {
    font-size: 4rem; /* 64 px */
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 76.8px */
    letter-spacing: -0.64px;
  }

  h2 {
    font-size: 3.5rem; /* 56px */
    letter-spacing: -1.12px;
    line-height: 120%;
  }

  h3 {
    font-size: 2.5rem; /* 40px */
    letter-spacing: 1.2px;
    line-height: 120%;
    letter-spacing: -0.4px;
    font-style: normal;
    font-weight: 600;
  }

  h4 {
    font-size: 2rem; /* 32px */
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.32px;
  }

  h5 {
    font-size: 1.5rem; /* 24px */
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.24px;
  }

  h6 {
    font-size: 1.25rem; /* 20px */
    letter-spacing: 0.6px;
    line-height: 140%;
  }

  .subheading {
    font-size: 18px;
  }
}

body {
  -webkit-font-smoothing: antialiased; /* For older versions of Safari */
  -moz-osx-font-smoothing: grayscale; /* For newer versions of Safari */
}

/* **************************************** */
/*             Button Styling               */
/* **************************************** */

.primary-btn {
  color: white;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  background: #ff8181;
}

input {
  border: none;
  padding-bottom: 5px;
  outline: none; /* Remove default focus outline */
}

.body-settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* **** Buttons **** */

button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  background: #ff8181;
  appearance: none;
  border: none;
  outline: none;
}

button:hover {
  cursor: pointer;
}

.button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  background: #ff8181;
  appearance: none;
  border: none;
  outline: none;
}

.button:hover {
  cursor: pointer;
}

.primary {
  color: var(--color-white);
  font-size: 0.875rem; /* 14px */
  letter-spacing: -0.16px;
  font-style: normal;
  font-weight: 600;
}

.secondary {
  color: var(--accent-color-dark);
  display: flex;
  /* padding: 12px 16px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 2px solid #ff8181;
  background: #fffbf6;
  font-weight: 600;
}

.newsletter {
  display: flex;
  width: 100%;
  /* padding: 12px 16px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

@media (min-width: 608px) {
  button {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #ff8181;
    appearance: none;
    border: none;
    outline: none;
  }
}

/* ********************************* */
/*              Nav Bar              */
/* ********************************* */

.nav {
  background-color: #fffbf6;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-logo {
  width: 140px;
  height: 40px;
  cursor: pointer;
}

.container-nav {
  padding: 16px 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.navigation-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  color: var(--accent-color-dark);
}

/* ********************************* */
/*            Toggle Menu            */
/* ********************************* */

div#mobileNav {
  display: none;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(
    --accent-color-dark
  ); /* Example background color for the menu */
  z-index: 1000; /* Ensure it's above other content */
  /* padding: 2rem; */
  box-sizing: border-box;
  /* flex-direction: column; */
}

.menu-nav {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
}

.menu-content {
  display: inline-flex; /* Change inline-flex to flex */
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

.menu-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.menu-text {
  color: #fff;
  font-family: var(--ff-primary);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 43.2px */
  letter-spacing: -0.36px;
}

.menu-footer {
  position: absolute;
  bottom: 0;
  left: 0; /* Adjust as needed */
  right: 0; /* Adjust as needed */
  display: flex;
  padding: 16px 16px 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-items: center;
}

.menu-footer-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-footer-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu-footer-text {
  color: var(--color-white);
  font-family: var(--ff-primary);
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.16px;
}

.hamburger-menu {
  width: 24px;
  height: 24px;
}

.horizontal-line {
  background: #ffe4cc;
  width: 100%;
  height: 1px;
}

.menu-content a {
  color: var(--color-white); /* Set the desired color */
  text-decoration: none; /* Remove the underline */
}

.btn-mobile {
  font-size: 13px;
}

.menu-footer-icons a {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  align-items: center;
}

.menu-footer-icons img {
  display: block;
  width: 24px;
  height: 24px;
}

@media (min-width: 426px) {
  .nav {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .menu-nav {
    padding: 24px;
  }

  .container-nav {
    display: flex;
    flex-direction: row;
    padding: 24px 24px;
    width: 100%;
    max-width: 720px;
  }
}

@media (max-width: 768px) {
  .navigation-links {
    display: none;
  }
}

@media (min-width: 769px) {
  .container-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 868px;
  }

  .hamburger-menu {
    display: none;
  }

  div#mobileNav {
    display: none;
  }
}

@media (min-width: 1025px) {
  .container-nav {
    width: 100%;
    max-width: 992px;
  }
}

/* **************************************** */
/*               Hero                       */
/* **************************************** */

.section-hero {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 48px 16px 24px 16px;
  align-items: center;
  background-color: var(--cream);
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
}

.hero-image {
  display: flex;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.container-hero-text {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.hero-text-change {
  color: #ffbfb8;
}

.moving-titles {
  overflow: hidden;
  height: 64px;
  /* height: auto; */
}

span.header {
  display: block;
  height: 100%;
  animation: spin_words 6s infinite;
  color: #ffbfb8;
}

@keyframes spin_words {
  10% {
    transform: translateY(-112%);
  }
  25% {
    transform: translateY(-100%);
  }
  35% {
    transform: translateY(-212%);
  }

  50% {
    transform: translateY(-200%);
  }

  60% {
    transform: translateY(-312%);
  }

  75% {
    transform: translateY(-300%);
  }

  85% {
    transform: translateY(-412%);
  }

  100% {
    transform: translateY(-400%);
  }
}

/* I have altered the dimensions of each <div> element
due to the size changes that are caused by the transform CSS property.
*/

/* SMALLER THAN MOBILE-SMALL VIEWPORT */

@media (max-width: 320px) {
  .container-hero-images {
    width: 100%;
    /* background-size: cover; */
  }
}

/* LARGER THAN MOBILE-SMALL VIEWPORT */

@media (min-width: 320px) {
  .container-hero-images {
    width: 100%;
    /* height: auto; */
  }

  .container-hero-text {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .container-hero {
    width: 100%;
    max-width: 480px;
    justify-content: center;
    align-items: center;
    padding: 40px auto;
  }

  .container-hero-text {
    max-width: 100%;
  }

  .moving-titles {
    overflow: hidden;
    height: 64px;
    /* height: auto; */
  }
}

@media (min-width: 769px) {
  .container-hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* max-width: 1024px; */
    max-width: 900px;
  }

  .container-hero-image {
    order: 2;
    width: 50%;
  }

  .container-hero-text {
    order: 1;
  }
  .moving-titles {
    overflow: hidden;
    height: 96px;
    /* height: auto; */
  }
}

@media (min-width: 1025px) {
  .container-hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    /* max-width: 688px; */
  }
}

/* **************************************** */
/*                Concept                   */
/* **************************************** */

.section-our-concept {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-our-concept {
  display: flex; /*was inline-flex*/
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 40px 16px 24px 16px;
  align-items: center; /*was flex-start*/
  background-color: var(--cream);
  box-sizing: border-box;
}

.container-our-concept-images {
  display: flex;
  padding: 25px 0px;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  box-sizing: border-box;
  margin: 0px;
  flex-shrink: 0;
}

.concept-img-1 {
  display: flex;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 298px; */
  /* height: 187px; */
  /* background-color: #e1e1e1; */

  /* flex-shrink: 0; */
  /* border-radius: 16px; */
  /* overflow: hidden; */

  /* background-image: url("../assets/imgs/friends-4.webp"); */
  /* background-size: auto 100%; */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
}

.container-our-concept-text {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

/* SMALLER THAN MOBILE-SMALL VIEWPORT */

@media (max-width: 320px) {
  .container-our-concept-images {
    width: 100%;
    /* background-size: cover; */
  }
}

/* LARGER THAN MOBILE-SMALL VIEWPORT */

@media (min-width: 320px) {
  .container-our-concept-images {
    width: 100%;
    /* height: auto; */
  }
  .concept-img-1 {
    width: 100%;
    max-width: 100%;
    height: auto;
    /* background-size: contain; */
    /* height: 100%; */
  }

  .container-our-concept-text {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .container-our-concept {
    width: 100%;
    max-width: 480px;
    justify-content: center;
    align-items: center;
    padding: auto;
  }

  .container-our-concept-images {
    /* padding: 25px 40px; */
    width: 100%;
    max-width: 368px;
  }

  .container-our-concept-text {
    max-width: 360px;
  }
}

@media (min-width: 769px) {
  .container-our-concept {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 900px;
  }

  .container-our-concept-images {
    order: 1;
    width: 50%;
    justify-content: left;
  }

  .concept-img-1 {
    width: 80%;
    max-width: 100%;
    height: auto;
    /* background-size: contain; */
    /* height: 100%; */
  }

  .container-our-concept-text {
    order: 2;
  }
}

@media (min-width: 1025px) {
  .container-our-concept {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    /* max-width: 688px; */
  }
}

/* **************************************** */
/*               Principles                 */
/* **************************************** */

.section-principles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container-principles {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: var(--cream);
  padding: 40px 16px;
  width: 100%;
  gap: 24px;
  box-sizing: border-box;

  /* max-width: 1128px; */
}

.container-principles-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  /* align-self: stretch; */
}

.principles-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.principles-sub-header {
  align-self: stretch;
  color: #a4a4a4;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
}

.principles-header-text {
  align-self: stretch;
  color: #333;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.64px;
}

.container-value-props {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  box-sizing: border-box;
}

.container-two-value-props {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
}

@media (min-width: 425px) {
  .container-value-props {
    padding: 16px;
    width: 100%;
    max-width: 480px;
  }

  .container-two-value-props {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 480px;
    padding: 0px;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .container-principles {
    width: 100%;
    max-width: 480px;
    justify-content: center;
    align-items: center;
    padding: auto;
  }

  .container-our-concept-text {
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .container-principles {
    width: 100%;
    max-width: 900px;
  }

  .container-two-value-props {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    /* max-width: 1024px; */
  }
  .container-value-props {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    max-width: 992px;
    padding: 0px;
  }

  .principles-sub-header {
    font-size: 18px;
  }
}

@media (min-width: 1025px) {
  .container-value-props {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 1024px;
    gap: 24px;
  }

  .container-principles {
    width: 100%;
    max-width: 1024px;
  }
}

/* **************************************** */
/*               Value Prop                 */
/* **************************************** */

.value-prop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%; /* Smallest mobile view*/
  max-width: 264px;
  box-sizing: border-box;
}



/* **************************************** */
/*         Testimonial Section              */
/* **************************************** */

.container-testimonial-section {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 16px;
  gap: 48px;
  width: 100%;
  box-sizing: border-box;
}

.testimonial-heading {
  color: #333;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.64px;
  /* align-self: stretch; */
}

.our-friends {
  color: #ff8181;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.64px;
}

@media (min-width: 425px) {
  .testimonial-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

/* **************************************** */
/*              Testimonials                */
/* **************************************** */

.container-testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
}

.testimonial-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
}

.testimonial-card {
  /* color: red; */
  max-width: 317px;
}

@media (min-width: 425px) and (max-width: 768px) {
  .container-testimonials {
    width: 100%;
    max-width: 448px;
    justify-content: center;
    align-items: center;
    padding: auto;
    /* padding: 16px; */
  }

  .testimonial-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    align-items: flex-start;
    gap: 24px;
    box-sizing: border-box;
  }
}

@media (min-width: 769px) {
  .container-testimonials {
    width: 100%;
    max-width: 868px;
    /* padding: 16px; */
  }

  .testimonial-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    gap: 40px;
    box-sizing: border-box;
  }
}

@media (min-width: 1025px) {
  .container-testimonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    padding: 16px;
    /* max-width: 688px; */
  }
}

/* **************************************** */
/*           Testimonial Card               */
/* **************************************** */

.testimonial-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.testimonial-avatar {
  border-radius: 64px;
  /* background: url(<path-to-image>) lightgray 50% / cover no-repeat; */
  width: 64px;
  height: 64px;
}

/* **************************************** */
/*             Newsletter                   */
/* **************************************** */

.container-newsletter {
  background-color: #ffede3;
  padding: 48px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container-newsletter-text {
  background-color: #ffede3;
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.newsletter-input-field {
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  /* color: var(--color-white); */
}

.newsletter-img {
  display: none;
}

.container-newsletter-items {
  display: flex;
  background-color: #ffede3;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* max-width: 1440px; */
}

.newsletter-items {
  display: flex;
  align-items: center;
  /* max-width: 992px; */
}

/* Media Queries */

@media (min-width: 425px) and (max-width: 768px) {
  .newsletter-items {
    width: 100%;
    max-width: 480px;
    justify-content: center;
    align-items: center;
    padding: auto;
  }
}

@media (min-width: 769px) {
  .newsletter-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 900px;

    /* max-width: 1024px; */
  }

  .container-newsletter {
    background-color: #ffede3;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    padding: auto;
    display: flex;
    gap: 16px;
  }

  .container-newsletter-text {
    background-color: #ffede3;
    display: inline-flex;
    align-items: flex-start;
    width: 80%;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 713px;
  }

  .newsletter-input-field {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 20px;
    width: 80%;
    box-sizing: border-box;
    /* color: var(--color-white); */
  }

  .newsletter-img {
    display: block;
    width: 50%;
    height: 100%;
    margin-top: 40px;
    overflow: hidden;
  }
}

@media (min-width: 1025px) {
  .newsletter-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
  }
}

/* **************************************** */
/*            Input Field                   */
/* **************************************** */

/* .container-input-field {
  width: 100%;
} */

.input-submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.input-first-name {
  color: #ffb5b5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
  width: 100%;
  background-color: #ffede3;
  padding: 0px;
}

.input-first-name::placeholder {
  color: #ffb5b5;
}

.input-email-address {
  color: #ffb5b5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
  width: 100%;
  background-color: #ffede3;
  padding: 0px;
  /* gap: 8px; */
}

.container-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}
.input-underline {
  /* width: 288px; */
  width: 100%;
  height: 1px;
  background: var(--accent-color-dark);
  /* align-items: flex-start; */
}

.input-email-address::placeholder {
  color: #ffb5b5;
}

/* **************************************** */
/*            Final Section                 */
/* **************************************** */

.container-final-section {
  display: flex-column;
  box-sizing: border-box;
  width: 100%;
  padding: 48px 16px;
}

.container-final-section-imgs {
  display: flex;
  flex-direction: row;
  padding: 25px 0px;
  align-items: left;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.final-section-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.fs-img3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 136px;
  background-color: #a1a1a1;
  flex-shrink: 0;
  border-radius: 16px;
  margin-left: -40px;
}

.container-final-section-text {
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
}

.container-final-section-text h1 {
  text-align: center;
}

@media (min-width: 426px) {
  .container-final-section-text {
    display: flex;
    width: 100%;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
  }
  .final-section-image {
    max-width: 480px;
  }
}

@media (min-width: 769px) {
  .container-final-section-text {
    display: flex;
    width: 100%;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
  }
  .final-section-image {
    max-width: 900px;
  }
}

@media (min-width: 1025px) {
  .container-final-section-text {
    display: flex;
    width: 100%;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
  }
  .final-section-image {
    max-width: 1024px;
  }
}

/* **************************************** */
/*                Discover                  */
/* **************************************** */

.section-discover-page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-discover-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.header-discover-page {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  box-sizing: border-box;
  gap: 24px;
}

.container-discover-img {
  display: flex;
  padding: 40px 0px 0px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
}

.discover-image {
  width: 100%;
  height: auto;
  /* max-width: 661px; */
  /* max-height: 622px; */
  border-radius: 40px;
  box-sizing: border-box;
}

.container-top-text {
  display: inline-flex;
  padding: 24px 16px 64px 16px;
  flex-direction: column;
  align-items: flex-start;
  /* max-width: 605px; */
  gap: 24px;
}

.container-heading-text {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.our-intention {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.our-intention h2 {
  font-size: 48px;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 425px) {
  .container-discover-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 480px;
  }
}

@media (min-width: 769px) {
  .container-discover-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 868px;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
  }

  .header-discover-page {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .container-discover-img {
    max-width: 540px;
  }

  .discover-title {
    max-width: 400px;
  }
}

@media (min-width: 1025px) {
  .container-discover-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
  }
}

/* **************************************** */
/*                  FAQ                     */
/* **************************************** */

.container-faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-faq-page {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  box-sizing: border-box;
  gap: 24px;
}

.container-faq-img {
  display: flex;
  flex-direction: row;
  padding: 40px 16px 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 56px;
}

.container-faq-text {
  display: flex;
  flex-direction: row;
  padding: 40px 16px;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  box-sizing: border-box;
}

.q-and-a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.faq-image {
  width: 100%;
  height: auto;
  max-width: 661px;
  max-height: 622px;
  border-radius: 40px;
}

@media (min-width: 425px) {
  .container-faq-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 480px;
  }
  .header-faq-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    align-items: center;
    justify-content: space-between;
  }
  .container-faq-img {
    padding: 0px;
  }
}

@media (min-width: 769px) {
  /* .container-faq-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 868px;
  } */

  .container-faq-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 868px;
  }

  .header-faq-page {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 868px;
    justify-content: space-between;
    align-items: center;
  }
  .container-discover-img {
    max-width: 540px;
  }

  .faq-title {
    max-width: 400px;
  }
}

@media (min-width: 1025px) {
  .container-faq-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1024px;
  }

  .header-faq-page {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 1024px;
    align-items: center;
    justify-content: space-between;
  }
}

/* **************************************** */
/*               Feature                    */
/* **************************************** */

.container-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 0px 16px;
}

.feature-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.number-color {
  color: var(--font-color-body);
}

.feature-demo {
  width: 50%;
}

.container-feature-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

/* .container-normal {
  display: flex;
}

.container-reverse {
  display: flex;
  flex-direction: row-reverse;
} */

@media (min-width: 769px) {
  /* .container-feature {
    display: flex;
    flex-direction: row;
  } */

  .container-feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 32px;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .container-normal {
    display: flex;
    flex-direction: row;
  }

  .container-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .feature-demo {
    width: 212px;
  }

  .container-feature-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 480px;
    gap: 16px;
  }
}

@media (min-width: 1025px) {
}

/* **************************************** */
/*               Footer                     */
/* **************************************** */

.section-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  padding: 24px 16px;
  box-sizing: border-box;
}

.container-logo-slogan {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 16px;
}

.container-footer-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.cyasoon-slogan {
  color: #ff8181;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fw-medium);
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
  width: 100%;
}

.social-footer-column {
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
}

.container-social-media {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  box-sizing: border-box;
}

.container-social-media-components {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--accent-color-dark);
  font-weight: var(--fw-medium);
}

.container-bottom-links {
  width: 100%;
}

.footer-logo {
  width: 170px;
  height: 48px;
}

.container-bottom-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  padding-top: 16px;
  font-weight: var(--fw-semi-bold);
  color: var(--accent-color-dark);
}

.three-links {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.footer-text-legal {
  color: var(--accent-color-dark);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: 150%; /* 18px */
}

@media (min-width: 320px) {
  .container-footer-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }

  .container-social-media-components {
    justify-content: space-between;

    max-width: 361px;
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  .container-footer {
    justify-content: center;
    align-items: center;
  }

  .container-footer-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    /* align-items: center; */
  }

  .container-social-media-components {
    justify-content: space-between;

    max-width: 361px;
  }
}

@media (min-width: 768px) {
  .container-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 900px;
  }

  .container-footer-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between !important;
  }

  .container-social-media {
    justify-content: right;
    width: 50%;
    max-width: 756px;
  }

  .container-social-media-components {
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 756px;
  }
}

@media (min-width: 1025px) {
  .container-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
  }
}

/* **************************************** */
/*         Social Media Tags                */
/* **************************************** */

.container-social-media-tag {
  display: flex;
  /* width: 120px; */
  width: 100%;
  padding: 8px 0px;
  align-items: center;
  gap: 4px;
}

/* **************************************** */
/*            Info Section                  */
/* **************************************** */

.our-principles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.our-story {
  display: flex;
}

/* **************************************** */
/*             Not Found                    */
/* **************************************** */

.section-not-found {
  display: flex;
  padding: 80px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.container-not-found {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.container-not-found p {
  padding: 0 0 0 4px;
}

.container-not-found-text {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.not-found-title {
  color: var(--font-color-body);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: 120%; /* 48px */
  letter-spacing: -0.4px;
}

/* **************************************** */
/*              Page Title                  */
/* **************************************** */

.subtitle {
  color: var(--accent-color-dark);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;
  align-self: stretch;
}

.title {
  align-self: stretch;
  color: var(--font-color-body);
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 43.2px */
  letter-spacing: -0.36px;
}

/* **************************************** */
/*                  Q&A                     */
/* **************************************** */

.container-qa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.question {
  color: var(--font-color-body);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  width: 100%;
}

.answer {
  color: var(--font-color-body);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 22.5px */
  width: 100%;
}

/* **************************************** */
/*            Section Title                 */
/* **************************************** */

.container-section-title {
  padding: 40px;
}

.section-title-subheading {
  color: var(--accent-color-dark);
  font-weight: 400;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;
}
.section-title-heading {
  display: flex;
}

/*--------------------------------*/
/*  POLICY PAGES, CONTACT PAGES   */
/*--------------------------------*/

#outerContainer {
  margin: 0 auto;
}

header.policy,
header.contact {
  background: var(--cream, #FFFBF6);
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 2.2em 2.4em 1.4em 2.4em;
}

main.policy,
main.contact {
  padding: 0 2.4em 1.4em 2.4em;
}

#cyasoonLogoContainer {
  max-width: 200px;
  height: auto;
}

#cyasoonLogo {
  width: 100%;
  max-width: 200px;
  height: auto;
}

@media only screen and (min-width: 800px) {

  header.policy,
  header.contact {
    padding: 2.6em 3em 2em 3em;
  }

  main.policy,
  main.contact {
    padding: 0 3em 2em 3em;
  }
}

@media (min-width: 1025px) {
  #outerContainer {
    max-width: 1024px;
  }
}

/*--------------------------*/
/* Policy + Contact + Terms */
/*--------------------------*/

.policy h1,
.policy h2,
.policy h3,
.policy h4,
.policy h5,
.policy h6 {
  margin-top: 1.8rem;
  line-height: 1.24;
  color: var(--font-color-body, #323232);
}

.policy h1 {
  font-size: 1.8rem;
  margin-block-start: 0;
}

.policy h2 {
  font-size: 1.6rem;
}

.policy h3 {
  font-size: 1.2rem;
}

.policy h4 {
  font-size: 1rem;
  text-decoration: underline;
}

@media only screen and (min-width: 800px) {
  .policy h1 {
    font-size: 2rem;
  }
  
  .policy h2 {
    font-size: 1.8rem;
  }
  
  .policy h3 {
    font-size: 1.4rem;
  }
  
  .policy h4 {
    font-size: 1.2rem;
  }
}

.policy h1:not(:first-child),
.policy h2:not(:first-child),
.policy h3:not(:first-child),
.policy h4:not(:first-child),
.policy h5:not(:first-child),
.policy h6:not(:first-child) {
  margin-top: 1.8rem;
}

.policy h1:not(:last-child),
.policy h2:not(:last-child),
.policy h3:not(:last-child),
.policy h4:not(:last-child),
.policy h5:not(:last-child),
.policy h6:not(:last-child) {
  margin-bottom: 1.2rem;
}

.policy p,
.policy li,
.contact p,
.contact li {
  color: var(--font-color-body, #323232);
  font-size: 1rem;  /* 16px */
}

.policy p {
  margin-bottom: 1.2rem;
}

.policy li {
  margin-bottom: 0.8rem;
}