/**
  * 100 BROmnyThin
  * 100 BROmnyThinItalic
  * 300 BROmnyLight
  * 300 BROmnyLightItalic
  * 400 BROmnyRegular
  * 400 BROmnyRegularItalic
  * 500 BROmnyMedium
  * 500 BROmnyMediumItalic
  * 600 BROmnySemiBold
  * 600 BROmnySemiBoldItalic
  * 700 BROmnyBold
  * 700 BROmnyBoldItalic
  * 800 BROmnyBlack
  * 800 BROmnyBlackItalic
**/

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"), url("BROmnyThin-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyThin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyThin-webfont.woff2") format("woff2"),
    url("BROmnyThin-webfont.woff") format("woff"),
    url("BROmnyThin-webfont.ttf") format("truetype"),
    url("BROmnyThin-webfont.svg#BROmnyThin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyThinItalic-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyThinItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyThinItalic-webfont.woff2")
      format("woff2"),
    url("BROmnyThinItalic-webfont.woff")
      format("woff"),
    url("BROmnyThinItalic-webfont.ttf")
      format("truetype"),
    url("BROmnyThinItalic-webfont.svg#BROmnyThinItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"), url("BROmnyLight-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyLight-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyLight-webfont.woff2") format("woff2"),
    url("BROmnyLight-webfont.woff") format("woff"),
    url("BROmnyLight-webfont.ttf") format("truetype"),
    url("BROmnyLight-webfont.svg#BROmnyLight")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyLightItalic-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyLightItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyLightItalic-webfont.woff2")
      format("woff2"),
    url("BROmnyLightItalic-webfont.woff")
      format("woff"),
    url("BROmnyLightItalic-webfont.ttf")
      format("truetype"),
    url("BROmnyLightItalic-webfont.svg#BROmnyLightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"), url("BROmnyRegular-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyRegular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyRegular-webfont.woff2") format("woff2"),
    url("BROmnyRegular-webfont.woff") format("woff"),
    url("BROmnyRegular-webfont.ttf") format("truetype"),
    url("BROmnyRegular-webfont.svg#BROmnyRegular")
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyRegularItalic-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyRegularItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyRegularItalic-webfont.woff2")
      format("woff2"),
    url("BROmnyRegularItalic-webfont.woff")
      format("woff"),
    url("BROmnyRegularItalic-webfont.ttf")
      format("truetype"),
    url("BROmnyRegularItalic-webfont.svg#BROmnyRegularItalic")
      format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"), url("BROmnyMedium-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyMedium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyMedium-webfont.woff2") format("woff2"),
    url("BROmnyMedium-webfont.woff") format("woff"),
    url("BROmnyMedium-webfont.ttf") format("truetype"),
    url("BROmnyMedium-webfont.svg#BROmnyMedium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyMediumItalic-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyMediumItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyMediumItalic-webfont.woff2")
      format("woff2"),
    url("BROmnyMediumItalic-webfont.woff")
      format("woff"),
    url("BROmnyMediumItalic-webfont.ttf")
      format("truetype"),
    url("BROmnyMediumItalic-webfont.svg#BROmnyMediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnySemiBold-webfont.eot");
  src: local("BR Omny"),
    url("BROmnySemiBold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnySemiBold-webfont.woff2") format("woff2"),
    url("BROmnySemiBold-webfont.woff") format("woff"),
    url("BROmnySemiBold-webfont.ttf") format("truetype"),
    url("BROmnySemiBold-webfont.svg#BROmnySemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnySemiBoldItalic-webfont.eot");
  src: local("BR Omny"),
    url("BROmnySemiBoldItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnySemiBoldItalic-webfont.woff2")
      format("woff2"),
    url("BROmnySemiBoldItalic-webfont.woff")
      format("woff"),
    url("BROmnySemiBoldItalic-webfont.ttf")
      format("truetype"),
    url("BROmnySemiBoldItalic-webfont.svg#BROmnySemiBoldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyBold-webfont.woff2") format("woff2"),
    url("BROmnyBold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyBoldItalic-webfont.woff2")
      format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"), url("BROmnyBlack-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyBlack-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyBlack-webfont.woff2") format("woff2"),
    url("BROmnyBlack-webfont.woff") format("woff"),
    url("BROmnyBlack-webfont.ttf") format("truetype"),
    url("BROmnyBlack-webfont.svg#BROmnyBlack")
      format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: local("BR Omny"),
    url("BROmnyBlackItalic-webfont.eot");
  src: local("BR Omny"),
    url("BROmnyBlackItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("BROmnyBlackItalic-webfont.woff2")
      format("woff2"),
    url("BROmnyBlackItalic.otf") format("otf"),
    url("BROmnyBlackItalic-webfont.ttf")
      format("truetype"),
    url("BROmnyBlackItalic-webfont.svg#BROmnyBlackItalic")
      format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BayshoreBayshore";
  src: 
    url("font.woff2") format("woff2"), 
    url("font.woff") format("woff");
}
